export const updateImgixParams = (image, params) => {
  if (!params) return image

  const keysToUpdate = ['src', 'srcSet', 'srcWebp', 'srcSetWebp']
  keysToUpdate.forEach((key) => {
    try {
      if (params.fit) image[key] = image[key].replace('fit=max', `fit=${params.fit}`)
      if (params.q) image[key] = image[key].replace('&fit', `&q=${params.q}&fit`)
    } catch {}
  })

  return image
}