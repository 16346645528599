import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import dayjs from "dayjs"

import Layout from "../layout"
import SEO from "../seo"
import { linkResolver } from "../_helpers/linkResolver"
import { blogPostBodySerializer } from "../_helpers/blogPostBodySerializer"
import { updateImgixParams } from "../_helpers/updateImgixParams"

const BlogPost = ({ data }) => {
  let post = data.post.data
  let otherPosts = data.otherPosts.edges
  
  if (typeof window !== 'undefined' && window.__PRISMIC_PREVIEW_DATA__ && window.__PRISMIC_PREVIEW_DATA__.prismicBlogPost) {
    post = window.__PRISMIC_PREVIEW_DATA__.prismicBlogPost.data
    otherPosts = []
  }

  const date = post.date ? dayjs(post.date) : dayjs(data.post.last_publication_date)

  return (
    <Layout mainClass="resource-post">
      <SEO title={`Resource - ${post.title.text}`} />

      <section className="resource-header" data-sal="slide-up" data-sal-delay="150">
        <div className="row">
          <div className="inner">
            <h3>{post.title.text}</h3>
            <h6 className="violet">{date.format('MMM D YYYY')}</h6>
          </div>

          <Img fluid={post.cover_image.fluid} alt={post.title.text} imgStyle={{ objectFit: "cover" }} />
        </div>
        
        <div className="row spacer">
          <div className="inner">
            <div className="col"></div>
            <div className="col"></div>
          </div>
        </div>
      </section>

      <section className="resource-body">
        <div className="inner">
          <div className="body" dangerouslySetInnerHTML={{ __html: blogPostBodySerializer(post.body.html) }}></div>
        </div>
      </section>

      <section className="other-resources">
        <div className="inner">
          <div className="row">
            {otherPosts.map((otherPost, ind) => {
              return <OtherPost data={otherPost.node} ind={ind} key={ind} />
            })}
          </div>
        </div>
      </section>

      <section className="subscribe-form">
        <div className="inner">
          <div className="row">
            <div className="col text">
              <h3>Subscribe to VergeSense</h3>
              <p>Get the latest posts delivered right to your inbox.</p>
            </div>

            <div className="col form">
              <form 
                action="https://vergesense.us17.list-manage.com/subscribe/post?u=865cbe8dea5eaaae1f8ccf1ad&amp;id=2f4b515b47"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form">

                <input type="email" defaultValue="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Your email address" required />
                <div style={{position: 'absolute', left: "-5000px"}} aria-hidden="true">
                  <input type="text" name="b_865cbe8dea5eaaae1f8ccf1ad_2f4b515b47" tabIndex="-1" defaultValue="" />
                </div>
                <button type="submit" className="button violet">Subscribe</button>
              </form>
              
              {/* <form>
                <input type="email" placeholder="Your email address" />
                <button className="button violet">Subscribe</button>
              </form> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BlogPost

const OtherPost = ({ data, ind }) => {
  // console.log(data)
  
  if (!data) return null
  const post = data.data

  return (
    <div className="other-resource">
      <div className="post">
        <div className="post-image">
          <Link to={linkResolver(data)}>
            <Img fixed={updateImgixParams(post.cover_image.fixed, {fit: 'crop'})} alt={post.title.text} />
          </Link>
        </div>

        <div className="post-content">
          <h5><Link to={linkResolver(data)}>{post.title.text}</Link></h5>
          <div className="snippet" dangerouslySetInnerHTML={{ __html: post.snippet.html }}></div>
          <Link to={linkResolver(data)} className="button violet">Read more</Link>
        </div>
      </div>
    </div>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($uid: String!) {
    post: prismicBlogPost(uid: {eq: $uid}) {
      uid
      first_publication_date
      last_publication_date
      data {
        date
        title {
          html
          text
        }
        cover_image {
          fluid(maxWidth: 1600, maxHeight: 750) {
            ...GatsbyPrismicImageFluid
          }
        }
        snippet {
          html
          text
        }
        body {
          html
          text
        }
      }
    }
    otherPosts: allPrismicBlogPost(limit: 2, filter: {uid: {ne: $uid}}) {
      edges {
        node {
          type
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              html
              text
            }
            cover_image {
              fixed(width: 600, height: 450) {
                ...GatsbyPrismicImageFixed
              }
            }
            snippet {
              html
              text
            }
          }
        }
      }
    }
  }
`